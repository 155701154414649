import type { AppProps } from "next/app";
import Script from "next/script";

import { Providers } from "components/providers/Providers";

function Application({ Component, pageProps }: AppProps) {
  return (
    <Providers>
      {process.env.NEXT_PUBLIC_URL ===
      "https://music-care-pwa-staging.aws.innovasium.com" ? (
        <>
          <Script
            id="test"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              window.markerConfig = {
                destination: '623ca1088dbb647780ef2eb7', 
                source: 'snippet'
              };
            `,
            }}
          />
          <Script
            id="test2"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
            `,
            }}
          />
        </>
      ) : null}
      <Component {...pageProps} />
    </Providers>
  );
}

export default Application;

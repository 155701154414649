import type { Theme, ThemeOptions } from "@mui/material";
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import merge from "lodash/merge";

import { lightShadows, darkShadows } from "./shadows";

import { THEMES } from "constants/themes";
import { Settings } from "contexts/SettingsContext";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    modalContrast: {
      card: string;
    };
  }

  interface PaletteOptions {
    modalContrast?: {
      card?: string;
    };
  }
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 50,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 50,
        },
        multiline: {
          borderRadius: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 0,
          display: "table",
          margin: "auto",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 12,
          marginBottom: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          viewBox: "0 0 26 26",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif',
    button: {
      fontWeight: 600,
      fontSize: "0.875rem",
    },
    h1: {
      fontSize: "3.5rem",
      fontWeight: 700,
      fontFamily: '"Nunito", sans-serif',
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      fontFamily: '"Nunito", sans-serif',
    },
    h3: {
      fontWeight: 400,
      fontSize: "2.25rem",
      fontFamily: '"Nunito", sans-serif',
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      fontFamily: '"Nunito", sans-serif',
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.5rem",
      fontFamily: '"Nunito", sans-serif',
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      fontFamily: '"Nunito", sans-serif',
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    overline: {
      fontWeight: 600,
      fontSize: "0.9rem",
    },
  },
  shape: {
    borderRadius: 16,
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
          },
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#399DEC",
          },
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: "#32587F",
        light: "#7783DB",
        dark: "#3C4693",
        background: "rgba(86, 100, 210, 0.08)",
        border: "rgba(86, 100, 210, 0.5)",
      },
      secondary: {
        contrastText: "#FFFFFF",
        main: "#399DEC",
      },
      background: {
        default: "#F4F5F7",
        login: "#EAF2F4",
        paper: "#ffffff",
        payment: "#ffffff",
        registrationCode: "#E9F5FE",
        infoDark: "#0B79D0",
        success: "#4CAF50",
        info: "#F2F3FB",
      },
      text: {
        primary: "#253858",
        secondary: "rgba(66, 82, 110, 0.86)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
        warning: "#FF9800",
        error: "#ff3333",
      },
      warning: {
        main: "#FF9800",
      },
      modalContrast: {
        card: "#ECECEC",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            backgroundColor: "#222b36",
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))",
          },
          detailPanel: {
            backgroundColor: "#222b36",
          },
          columnHeaders: {
            backgroundColor: "#222b36",
            borderRadius: 0,
          },
          columnHeaderTitle: {
            fontWeight: 600,
            color: "rgb(104, 142, 255)",
          },
          virtualScroller: {
            minHeight: "80px",
          },
          overlay: {
            background: "unset",
          },
          withBorderColor: {
            borderBottomColor: "rgba(145, 158, 171, 0.24)",
            borderLeftColor: "rgba(145, 158, 171, 0.24)",
            borderRightColor: "rgba(145, 158, 171, 0.24)",
            borderTopColor: "rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#171c24",
        paper: "#222b36",
        info: "rgba(255, 255, 255, 0.05)",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#688eff",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
      modalContrast: {
        card: "#222b36",
      },
    },
    shadows: darkShadows,
  },
};

export const createTheme = (
  config: Pick<Settings, "responsiveFontSizes" | "roundedCorners" | "theme">
): Theme => {
  const selectedTheme = config.theme ?? THEMES.LIGHT;
  const themeOptions = themesOptions[selectedTheme];

  const theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      ...(config.roundedCorners && {
        shape: {
          borderRadius: 16,
        },
      }),
    })
  );

  if (config.responsiveFontSizes) {
    return responsiveFontSizes(theme);
  }

  return theme;
};

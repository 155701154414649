export enum PlanBillingCycle {
  Annually = "ANNUALLY",
  Monthly = "MONTHLY",
}

export enum PlanType {
  Connect = "CONNECT",
  Learn = "LEARN",
  Certify = "CERTIFY",
  Free = "FREE",
}
export interface StripePrice {
  id: string;
  currency: string;
  unit_amount?: number | null;
}
export interface Plan {
  plan_id: string;
  name: string;
  description: string;
  max_licenses: number;
  is_archived: boolean;
  created_at: Date;
  updated_at: Date;
  type: PlanType;
  billing_cycle: PlanBillingCycle;
  stripe_product_id: string;
  trial_period_days: number;
  prices?: StripePrice[] | null;
}

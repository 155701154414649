import { gql } from "@apollo/client";

import { client } from "components/providers/ApolloClientProvider";

import { PlanType } from "types/Plan";
import { Profile } from "types/Profile";
import { Role } from "types/Role";

/**
 * LOGIN
 */
export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token {
        id
        refresh
        access
      }
      valid
    }
  }
`;

export interface LoginMutationData {
  login: {
    token: { id: string; refresh: string; access: string };
    valid: boolean;
  };
}

export interface LoginMutationVars {
  email: string;
  password: string;
}

export const login = async (body: LoginMutationVars) =>
  client.mutate<LoginMutationData, LoginMutationVars>({
    mutation: LOGIN_MUTATION,
    variables: body,
  });

/**
 * LOGOUT
 */
export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;

export interface LogoutMutationData {
  logout: {
    message: string;
  };
}

export const logout = async () =>
  client.mutate<LogoutMutationData>({
    mutation: LOGOUT_MUTATION,
  });

/**
 * REGISTER
 */
export const REGISTER_MUTATION = gql`
  mutation Register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $organization: String
    $first_login: Boolean!
    $registration_date: String!
    $captcha: String!
  ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      organization: $organization
      first_login: $first_login
      registration_date: $registration_date
      captcha: $captcha
    ) {
      profile_id
      firstName
      lastName
      organization
      email
    }
  }
`;

export interface RegisterMutationData {
  register: {
    profile_id: string;
    firstName: string;
    lastName: string;
    organization: string;
    email: string;
  };
}

export interface RegisterMutationVars {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organization?: string;
  first_login: boolean;
  registration_date: string;
  captcha: string;
}

export const register = async (
  body: Omit<
    RegisterMutationVars,
    "first_login" | "organization" | "registration_date"
  >
) =>
  client.mutate<RegisterMutationData, RegisterMutationVars>({
    mutation: REGISTER_MUTATION,
    variables: {
      ...body,
      first_login: false,
      registration_date: new Date().toISOString(),
    },
  });
/**
 * FORGOT PASSWORD
 */
export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
      success
    }
  }
`;

export interface ForgotPasswordMutationData {
  forgotPassword: {
    message: string;
    success: boolean;
  };
}

export interface ForgotPasswordMutationVars {
  email: string;
}

export const forgotPassword = async (body: ForgotPasswordMutationVars) =>
  client.mutate<ForgotPasswordMutationData, ForgotPasswordMutationVars>({
    mutation: FORGOT_PASSWORD_MUTATION,
    variables: body,
  });

/**
 * RESET PASSWORD
 */
export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!, $password: String!, $code: String!) {
    resetPassword(email: $email, password: $password, code: $code) {
      message
      success
    }
  }
`;

export interface ResetPasswordMutationData {
  resetPassword: {
    message: string;
    success: boolean;
  };
}

export interface ResetPasswordMutationVars {
  email: string;
  password: string;
  code: string;
}

export const resetPassword = async (body: ResetPasswordMutationVars) =>
  client.mutate<ResetPasswordMutationData, ResetPasswordMutationVars>({
    mutation: RESET_PASSWORD_MUTATION,
    variables: body,
  });

/**
 * Session
 */

export interface SessionQueryData {
  session: {
    profile: Profile;
    planType: PlanType;
    roles: Role[];
  };
}

export const SESSION_QUERY = gql`
  query Session {
    session {
      profile {
        profile_id
        firstName
        lastName
        organization
        email
        subscribed
        first_login
        certified
        recertify
      }
      planType
      roles {
        role_id
        name
        description
        created_at
        updated_at
      }
    }
  }
`;

export const getSession = async () =>
  client.query<SessionQueryData>({
    query: SESSION_QUERY,
  });

/**
 * REFRESH
 */

export interface RefreshQueryData {
  refreshToken: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  };
}

export const REFRESH_MUTATION = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      idToken
    }
  }
`;

export const refreshToken = async ({ token }: { token: string }) =>
  client.mutate<RefreshQueryData>({
    mutation: REFRESH_MUTATION,
    variables: { refreshToken: token },
  });

import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { client } from "components/providers/ApolloClientProvider";

import { Language } from "types/Language";

export interface UpdateLanguageFileData {
  updateLanguageFile: Language;
}

export type UpdateLanguageFileVars = {
  newLanguageFile: string;
};

export const UPDATE_LANGUAGE_FILE_MUTATION = gql`
  mutation UpdateLanguageFile($newLanguageFile: String!) {
    updateLanguageFile(new_language_file: $newLanguageFile) {
      language_id
      language_file
      created_at
      updated_at
    }
  }
`;

export const useUpdateLanguageFileMutation = (
  options?: MutationHookOptions<UpdateLanguageFileData, UpdateLanguageFileVars>
) =>
  useMutation<UpdateLanguageFileData, UpdateLanguageFileVars>(
    UPDATE_LANGUAGE_FILE_MUTATION,
    options
  );

export const setLang = async (body: UpdateLanguageFileVars) =>
  client.mutate<UpdateLanguageFileData, UpdateLanguageFileVars>({
    mutation: UPDATE_LANGUAGE_FILE_MUTATION,
    variables: body,
  });

import { getLang } from "queries/useLatestLanguageQuery";
import { setLang } from "queries/useUpdateLanguageFileMutation";
import React, { createContext, useEffect, useReducer, type FC } from "react";

import { MusiccareIntl } from "types/Language";

type IntlContextAction = InitializeAction | SetLanguageAction;

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    language_file: MusiccareIntl;
  };
};

type SetLanguageAction = {
  type: "SET_LANGUAGE";
  payload: {
    language_file: MusiccareIntl;
  };
};

/**
 * Type of IntlContext state
 */
interface State {
  language: string;
  intl: MusiccareIntl | null;
  setIntl: (newIntl: MusiccareIntl) => Promise<void>;
}

const initialState: State = {
  language: "en",
  intl: null,
  setIntl: async () => Promise.resolve(undefined),
};

const reducer = (state: State, action: IntlContextAction): State => {
  switch (action.type) {
    case "INITIALIZE": {
      const { language_file } = action.payload;
      return {
        ...state,
        intl: language_file,
      };
    }
    case "SET_LANGUAGE": {
      return {
        ...state,
        intl: action.payload.language_file,
      };
    }
    default:
      return { ...state };
  }
};

const IntlContext = createContext<State>({
  ...initialState,
});

interface IntlProviderProps {
  children: React.ReactNode;
}

export const IntlProvider: FC<IntlProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getLang()
      .then((result) => {
        dispatch({
          type: "INITIALIZE",
          payload: {
            language_file: JSON.parse(
              result.data.latestLanguage.language_file
            ) as MusiccareIntl,
          },
        });
      })
      .catch(() => {
        // Also probably a sentry error
      });
  }, []);

  const setIntl = async (newIntl: MusiccareIntl) => {
    await setLang({ newLanguageFile: JSON.stringify(newIntl) })
      .then((result) => {
        if (result.data?.updateLanguageFile) {
          dispatch({
            type: "SET_LANGUAGE",
            payload: {
              language_file: JSON.parse(
                result.data.updateLanguageFile.language_file
              ) as MusiccareIntl,
            },
          });
        }
      })
      .catch(() => {
        // Idk probably log a sentry error or something I guess
      });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      setIntl,
    }),
    [state]
  );

  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>;
};

export default IntlContext;

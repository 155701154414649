import { useQuery, gql, QueryHookOptions } from "@apollo/client";

import { client } from "components/providers/ApolloClientProvider";

import { Language } from "types/Language";

export interface LatestLanguageData {
  latestLanguage: Language;
}

export const LATEST_LANGUAGE_QUERY = gql`
  query LatestLanguage {
    latestLanguage {
      language_id
      language_file
      created_at
      updated_at
    }
  }
`;

export const useLatestLanguageQuery = (
  options?: QueryHookOptions<LatestLanguageData>
) => useQuery<LatestLanguageData>(LATEST_LANGUAGE_QUERY, options);

export const getLang = async () =>
  client.query<LatestLanguageData>({
    query: LATEST_LANGUAGE_QUERY,
  });

import * as Auth from "queries/auth";
import React, { createContext, useEffect, useReducer, type FC } from "react";

import { AuthContextAction } from "./AuthContextActions";

import { client } from "components/providers/ApolloClientProvider";

import { PlanType } from "types/Plan";
import type { Profile } from "types/Profile";
import { Role } from "types/Role";

/**
 * Type of AuthContext state
 */
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: Profile | null;
  planType: PlanType;
  roles: Role[];
}

/**
 * Extended state with functions to mutate state
 */
interface AuthContextState extends State {
  login: (body: Auth.LoginMutationVars) => Promise<void>;
  logout: () => Promise<void>;
  forgotPassword: (body: Auth.ForgotPasswordMutationVars) => Promise<void>;
  forgotPasswordReset: (body: Auth.ResetPasswordMutationVars) => Promise<void>;
  register: (
    body: Omit<Auth.RegisterMutationVars, "first_login" | "registration_date">
  ) => Promise<void>;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  planType: PlanType.Free,
  roles: [],
};

const reducer = (state: State, action: AuthContextAction): State => {
  switch (action.type) {
    case "INITIALIZE": {
      const { isAuthenticated, user, planType, roles } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        planType,
        roles,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case "LOGOUT":
      return {
        ...initialState,
        isInitialized: true,
      };
    case "REGISTER":
      return { ...state };
    case "VERIFY_CODE":
      return { ...state };
    case "RESEND_CODE":
      return { ...state };
    case "PASSWORD_RECOVERY":
      return { ...state };
    case "PASSWORD_RESET":
      return { ...state };
    case "NEW_PASSWORD":
      return { ...state };
    default:
      return { ...state };
  }
};

const AuthContext = createContext<AuthContextState>({
  ...initialState,
  login: async () => Promise.resolve(undefined),
  logout: async () => Promise.resolve(),
  forgotPassword: async () => Promise.resolve(),
  forgotPasswordReset: async () => Promise.resolve(),
  register: async () => Promise.resolve(),
});

export const AuthProvider: FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    Auth.getSession()
      .then((result) => {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: result.data.session.profile,
            planType: result.data.session.planType,
            roles: result.data.session.roles,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            planType: PlanType.Free,
            roles: [],
          },
        });
      });
  }, []);

  const login = async (body: Auth.LoginMutationVars) => {
    const result = await Auth.login(body);

    if (result.errors?.length) {
      throw new Error(result.errors[0].message);
    }

    if (result.data) {
      // Get session
      localStorage.setItem("access-token", result.data.login.token.access);
      localStorage.setItem("refresh-token", result.data.login.token.refresh);
      localStorage.setItem("id-token", result.data.login.token.id);

      const session = await Auth.getSession();

      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: true,
          user: session.data.session.profile,
          planType: session.data.session.planType,
          roles: session.data.session.roles,
        },
      });

      dispatch({
        type: "LOGIN",
      });
    }
  };

  const logout = async () => {
    const { errors } = await Auth.logout();
    if (errors?.length) {
      throw new Error(errors[0].message);
    }

    // Clear local storage
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    // Clear cache
    client.cache.reset();

    dispatch({
      type: "LOGOUT",
    });
  };

  const forgotPassword = async (body: Auth.ForgotPasswordMutationVars) => {
    const { errors } = await Auth.forgotPassword(body);
    if (errors?.length) {
      throw new Error(errors[0].message);
    }
    dispatch({
      type: "PASSWORD_RECOVERY",
    });
  };

  const forgotPasswordReset = async (body: Auth.ResetPasswordMutationVars) => {
    const { errors } = await Auth.resetPassword(body);
    if (errors?.length) {
      throw new Error(errors[0].message);
    }
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const register = async (
    body: Omit<Auth.RegisterMutationVars, "first_login" | "registration_date">
  ) => {
    const { errors } = await Auth.register(body);
    if (errors?.length) {
      throw new Error(errors[0].message);
    }
    dispatch({
      type: "REGISTER",
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      login,
      logout,
      forgotPassword,
      forgotPasswordReset,
      register,
    }),
    [state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
